@use "../global" as *;

.l-header {
  background-color: $color_white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;

  @media screen and (max-width: $lg) and (min-width: $md) {
    width: 1080px;
  }

  @media (max-width: $md) {
    box-shadow: none;
  }
}
