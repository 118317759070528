@use "../../global" as *;

.p-footer {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  text-align: center;
}

.p-footer__copyright {
  color: $color_black_secondary;
  font-size: 12px;
  letter-spacing: 0.05em;
}
