@use "../../global" as *;

.c-button {
  align-items: center;
  border-radius: 999px;
  display: flex;
  justify-content: center;
}

.c-button--gradient {
  align-items: center;
  background: $color_orange_gradient_y;
  border-radius: 999px;
  display: flex;
  justify-content: center;
}
