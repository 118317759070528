@use "../../global" as *;

/* ==========================================================================
  First View
  ========================================================================== */
.p-home-first-view {
  background-image: url(../img/first-view.png);
  background-position: center center;
  background-size: cover;
  height: 525px;
  padding: 134px 0 0;

  @media (max-width: $md) {
    background-image: url(../img/first-view-sp.png);
  }

  @media (max-width: $sm) {
    height: auto;
    min-height: 360px;
    padding: 20px 0 0;
  }
}

.p-home-first-view__heading {
  color: $color_orange;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.05em;

  @media (max-width: $md) {
    font-size: 30px;
  }
}

.p-home-first-view__lead {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-top: 30px;

  @media (max-width: $md) {
    font-size: 20px;
  }
}

.p-home-first-view__button-wrap {
  margin-top: 30px;
  max-width: 478px;

  &[data-visible="pc"] {
    @media (max-width: $md) {
      display: none;
    }
  }

  &[data-visible="sp"] {
    @media not all and (max-width: $md) {
      display: none;
    }

    @media (max-width: $md) {
      margin: 20px auto 0;
    }
  }
}

.p-home-first-view__emphasis {
  background: $color_orange_gradient_x;
  -webkit-background-clip: text;
  color: $color_orange;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  text-align: center;
  -webkit-text-fill-color: transparent;
  width: 170px;

  @media (max-width: $md) {
    font-size: 16px;
    width: 140px;
  }

  &:before,
  &:after {
    background-color: $color_orange;
    content: "";
    display: inline-block;
    height: 2px;
    position: absolute;
    top: 50%;
    width: 18px;
  }

  &:before {
    left: 0;
    -moz-transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  &:after {
    right: 0;
    -moz-transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
}

.p-home-first-view__button {
  background: $color_orange_gradient_x;
  color: $color_white;
  font-size: 20px;
  font-weight: bold;
  height: 75px;
  letter-spacing: 0.05em;
  margin-top: 5px;
  max-width: 478px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 16px;
    height: 60px;
  }
}

/* ==========================================================================
  About
  ========================================================================== */

.p-home-about {
  padding: 80px 0 120px;

  @media (max-width: $md) {
    padding: 40px 0 60px;
  }
}

.p-home-about__lead {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: $md) {
    font-size: 20px;
    letter-spacing: 0.05em;
  }
}

.p-home-about__item-grid {
  display: flex;
  justify-content: space-between;

  @media (max-width: $md) {
    margin-top: 10px;
    flex-direction: column;
  }
}

.p-home-about__item {
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  letter-spacing: 0.05em;
  padding: 40px 70px;
  width: calc((100% - 10px) / 2);

  @media (max-width: $md) {
    flex-direction: row;
    margin-top: 10px;
    padding: 20px 10px;
    width: 100%;
  }
}

.p-home-about_youtube{
  display: flex;
  justify-content: center;
  padding: 40px;
}

.p-home-about__item-image {
  height: 160px;
  margin: 0 auto;

  @media (max-width: $md) {
    align-items: center;
    display: flex;
    min-width: 76px;
    width: auto;
  }

  img {
    width: 370px;

    @media (max-width: $md) {
      width: auto;
    }
  }

  img[data-visible="pc"] {
    @media (max-width: $md) {
      display: none;
    }
  }

  img[data-visible="sp"] {
    @media not all and (max-width: $md) {
      display: none;
    }

    @media (max-width: $md) {
      display: block;
    }
  }
}

.p-home-about__item-image--large {
  margin: 0 auto;
  width: 255px;

  @media (max-width: $md) {
    align-items: center;
    display: flex;
    min-width: 76px;
    width: auto;
  }

  img[data-visible="pc"] {
    @media (max-width: $md) {
      display: none;
    }
  }

  img[data-visible="sp"] {
    @media not all and (max-width: $md) {
      display: none;
    }

    @media (max-width: $md) {
      display: block;
    }
  }
}

.p-home-about__item-body {
  flex-grow: 1;
  margin: 25px auto 0;

  @media (max-width: $md) {
    margin-left: 30px;
  }
}

.p-home-about__item-heading {
  color: $color_orange;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;

  @media (max-width: $md) {
    font-size: 16px;
    text-align: left;
  }
}

.p-home-about__item-description {
  font-size: 14px;
  letter-spacing: 0.05em;
  margin-top: 20px;

  @media (max-width: $md) {
    font-size: 14px;
    margin-top: 13px;
  }
}

.p-home-about__feature {
  align-items: center;
  display: flex;
  height: 182px;
  justify-content: center;
  margin: 80px auto 0;

  @media (max-width: $md) {
    height: auto;
    margin: 35px auto 0;
  }
}

.p-home-about__feature-image {
  width: 160px;

  @media (max-width: $md) {
    display: none;
  }
}

.p-home-about__feature-text {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin: 0 45px;
  text-align: center;

  span {
    color: $color_orange;
  }

  @media (max-width: $md) {
    font-size: 20px;
    letter-spacing: 0;
    margin: 0;
  }
}

.p-home-about__notion {
  color: $color_grey;
  font-size: 12px;
  letter-spacing: 0.05em;
  margin: 40px auto 0;
  padding-left: 55px;

  @media (max-width: $md) {
    margin-top: 20px;
    padding-left: 0;
  }
}

/* ==========================================================================
  Comparison
  ========================================================================== */
.p-home-comparison {
  background-color: $color_pale_orange;
  height: 870px;
  position: relative;

  @media (max-width: $md) {
    height: 840px;
  }
}

.p-home-comparison__container {
  left: 0;
  margin: 0 auto;
  max-width: 1080px;
  position: absolute;
  right: 0;
  top: -40px;
  width: 90%;

  @media (max-width: $md) {
    margin: 0 16px;
    width: auto;
  }
}

.p-home-comparison__box {
  background-color: $color_white;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 80px 57px 80px 80px;

  @media (max-width: $md) {
    padding: 40px 12px;
  }
}

.p-home-comparison__issue {
  margin: 40px auto 0;

  @media (max-width: $md) {
    max-width: 264px;
  }

  img[data-visible="pc"] {
    @media (max-width: $md) {
      display: none;
    }
  }

  img[data-visible="sp"] {
    @media not all and (max-width: $md) {
      display: none;
    }

    @media (max-width: $md) {
      display: block;
      margin: 0 auto;
    }
  }
}

.p-home-comparison__grid {
  align-items: center;
  display: flex;
  margin: 80px auto 0;

  @media (max-width: $md) {
    flex-direction: column-reverse;
    margin: 40px auto 0;
  }
}

.p-home-comparison__grid-image {
  max-width: 269px;

  @media (max-width: $md) {
    margin-top: 10px;
    max-width: 263px;
  }
}

.p-home-comparison__grid-body {
  letter-spacing: 0.05em;
  margin-left: 70px;

  @media (max-width: $md) {
    font-size: 24px;
    margin-left: 0;
    text-align: center;
  }
}

.p-home-comparison__our-company {
  font-size: 50px;
  font-weight: 600;

  span {
    background: $color_orange_gradient_x;
    -webkit-background-clip: text;
    color: $color_orange;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: $md) {
    font-size: 24px;
    text-align: center;
  }
}

.p-home-comparison__feature-list {
  margin-top: 20px;
}

.p-home-comparison__feature-item {
  display: flex;
  font-size: 20px;
  font-weight: 600;

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  span {
    margin-left: 18px;
    text-align: left;

    @media (max-width: $md) {
      margin-left: 10px;
    }
  }

  @media (max-width: $md) {
    font-size: 16px;
  }
}

.p-home-comparison__checkbox {
  @media (max-width: $md) {
    max-width: 15px;
    min-width: 15px;
  }
}

/* ==========================================================================
  Plot
  ========================================================================== */
.p-home-plot {
  padding: 80px 0;
}

.p-home-plot__grid {
  align-items: center;
  display: flex;
  margin-top: 40px;

  @media (max-width: $md) {
    flex-direction: column-reverse;
    margin-top: 20px;
    position: relative;
  }
}

.p-home-plot__grid-image {
  width: 550px;

  @media (max-width: $md) {
    left: 9%;
    max-width: 98px;
    min-width: 98px;
    position: absolute;
    top: 56%;
  }

  @media (max-width: $sm) {
    left: 2%;
  }

  img[data-visible="pc"] {
    @media (max-width: $md) {
      display: none;
    }
  }

  img[data-visible="sp"] {
    @media not all and (max-width: $md) {
      display: none;
    }
  }
}

.p-home-plot__description-area {
  margin-left: 60px;
  width: calc(100% - 550px - 60px);

  @media (max-width: $md) {
    margin-left: 0;
    width: 100%;
  }
}

.p-home-plot__description {
  font-size: 14px;
  letter-spacing: 0.05em;

  &:not(:first-of-type) {
    margin-top: 10px;

    @media (max-width: $md) {
      margin-left: auto;
      width: 59%;
    }
  }

  img {
    width: 77px;
  }
}

.p-home-plot__flow-box {
  align-items: center;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 460px;
  justify-content: center;
  margin-top: 80px;
  padding: 80px 0;

  @media (max-width: $md) {
    flex-direction: column;
    height: auto;
    margin-top: 40px;
    padding: 40px 18px;
  }
}

.p-home-plot__flow-item {
  display: flex;
  flex-direction: column;
  height: 300px;
  max-width: 255px;
  width: 100%;

  @media (max-width: $md) {
    height: auto;
    max-width: none;
    width: auto;
  }
}

.p-home-plot__flow-image {
  @media (max-width: $md) {
    margin: auto;
    max-width: 95px;
  }
}

.p-home-plot__flow-lead {
  letter-spacing: 0.05em;
  margin-top: auto;
  text-align: center;

  @media (max-width: $md) {
    margin-top: 10px;
  }
}

.p-home-plot__flow-arrow {
  margin: 0 30px;

  @media (max-width: $md) {
    margin: 10px auto 20px;
  }

  img[data-visible="pc"] {
    @media (max-width: $md) {
      display: none;
    }
  }

  img[data-visible="sp"] {
    @media not all and (max-width: $md) {
      display: none;
    }
  }
}

/* ==========================================================================
  Merit
  ========================================================================== */
.p-home-merit {
  background-color: $color_pale_orange;
  padding: 80px 0;

  @media (max-width: $md) {
    padding: 40px 0 20px;
  }
}

.p-home-merit__item-grid {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: $md) {
    flex-direction: column;
    margin-top: 20px;
  }
}

.p-home-merit__item {
  background-color: $color_white;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  letter-spacing: 0.05em;
  padding: 80px;
  width: calc(50% - 5px);

  @media (max-width: $md) {
    margin: 10px auto 0;
    max-width: 576px;
    padding: 20px 6px;
    width: 100%;
  }
}

.p-home-merit__image {
  margin: 0 auto;

  img[data-visible="pc"] {
    @media (max-width: $md) {
      display: none;
    }
  }

  img[data-visible="sp"] {
    @media not all and (max-width: $md) {
      display: none;
    }
  }
}

.p-home-merit__description {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-top: auto;
  text-align: center;

  @media (max-width: $md) {
    font-size: 16px;
    margin-top: 15px;
  }
}

/* ==========================================================================
  Voice
  ========================================================================== */
.p-home-voice {
  padding: 80px 0 60px;

  @media (max-width: $md) {
    padding: 40px 0 0;
  }
}

.p-home-voice__cards {
  margin-top: 40px;
}

.p-home-voice__card {
  width: calc((100% - 10px) / 4);

  @media (max-width: $md) {
    max-width: 232px;
    width: 100%;
  }
}

/* ==========================================================================
  Entry
  ========================================================================== */
.p-home-entry {
  padding: 60px 0 120px;

  @media (max-width: $md) {
    padding: 40px 0;
  }
}

.p-home-entry__flow {
  margin: 20px auto 0;
  max-width: 600px;
  text-align: center;
  width: 100%;
}

.p-home-entry__flow-item {
  align-items: center;
  border-radius: 10px;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  justify-content: center;
  letter-spacing: 0.05em;

  @media (max-width: $md) {
    font-size: 14px;
    height: 40px;
  }

  &:first-of-type {
    border: 2px solid #fed321;
  }

  &:nth-of-type(3) {
    border: 2px solid #ffa800;
  }

  &:nth-of-type(5) {
    border: 2px solid #ff9c05;
  }

  &:nth-of-type(7) {
    border: 2px solid #fe8f0b;
  }

  &:nth-of-type(9) {
    border: 2px solid #fe8410;
  }

  &.arrow {
    height: 14px;
    margin: 10px 0;

    @media (max-width: $md) {
      height: 9px;
    }

    img {
      height: 100%;
    }
  }
}

/* ==========================================================================
  Contact
  ========================================================================== */
.p-home-contact {
  background-color: $color_pale_orange;
  padding: 80px 0;
}

.p-home-contact__lead {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-top: 20px;
  text-align: center;

  @media (max-width: $md) {
    font-size: 16px;
    margin-top: 15px;
  }
}

.p-home-contact__form {
  margin: 40px auto 0;
  max-width: 500px;
}

.p-home-contact__form-group {
  &:not(:first-of-type) {
    margin-top: 10px;
  }
}

.p-home-contact__form-header {
  display: flex;
  letter-spacing: 0.05em;
  padding: 3px 0;

  @media (max-width: $md) {
    flex-direction: column;
  }

  label {
    color: $color_grey;
    font-size: 14px;

    @media (max-width: $md) {
      font-size: 12px;
    }
  }

  strong {
    color: $color_orange;
    font-size: 9px;
    margin-left: 2px;
    top: 3px;
    vertical-align: text-bottom;
  }
}

.p-home-contact__form-validation {
  color: $color_orange;
  font-size: 14px;
  margin-left: 10px;

  @media (max-width: $md) {
    font-size: 12px;
    margin-top: 5px;
  }
}

.p-home-contact__form-content {
  background-color: $color_white;
  border: 1px solid $color_white_secondary;
}

.p-home-contact__form-input {
  height: 40px;
  padding: 10px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 12px;
  }
}

.p-home-content__form-select {
  height: 100%;
  padding: 10px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 12px;
  }
}

.p-home-contact__form-textarea {
  height: 108px;
  padding: 10px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 12px;
  }
}

.p-home-contact__privacy-policy {
  background-color: $color_white;
  border: 1px solid $color_white_secondary;
  font-size: 14px;
  height: 100px;
  margin-top: 20px;
  overflow: auto;
  padding: 14px 12px 2px 27px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 12px;
    margin-top: 16px;
  }
}

.p-home-contact__agree-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.p-home-contact__checkbox {
  margin: 0;
  opacity: 0;
  width: 0;
}

.p-home-contact__agree-label:hover > .p-home-contact__checkbox-dummy {
  background: $color_white;
  border: solid 1px solid $color_white_secondary;
}

.p-home-contact__checkbox:focus + .p-home-contact__checkbox-dummy {
  background: $color_white;
  border: solid 1px solid $color_white_secondary;
}

.p-home-contact__checkbox:checked + .p-home-contact__checkbox-dummy {
  background: #fff;
  border: solid 1px solid $color_white_secondary;
}

.p-home-contact__checkbox:checked + .p-home-contact__checkbox-dummy::before {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hlY2siPjxwb2x5bGluZSBwb2ludHM9IjIwIDYgOSAxNyA0IDEyIj48L3BvbHlsaW5lPjwvc3ZnPg==")
    no-repeat center;
  background-size: contain;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.p-home-contact__checkbox-dummy {
  background: #fff;
  border: solid 1px $color_white_secondary;
  display: block;
  height: 16px;
  left: 0;
  position: relative;
  top: 0;
  width: 16px;
}

.p-home-contact__agree-text {
  display: block;
  font-size: 12px;
  margin-left: 8px;
}

.p-home-contact__agree {
  margin-top: 22px;
  text-align: center;

  @media (max-width: $md) {
    font-size: 12px;
  }
}

.p-home-contact__agree-text {
  margin-left: 8px;
}

.p-home-contact__button-wrap {
  margin-top: 40px;
}

.p-home-contact__confirm-button {
  background: $color_orange_gradient_x;
  border-left: 1px solid #fe8410;
  border-right: 1px solid #ffa800;
  color: $color_white;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0.05em;
  margin: 0 auto;
  max-width: 270px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 16px;
  }

  &:before,
  &:after {
    background: $color_orange_gradient_y;
    content: "";
    display: block;
    height: 1px;
  }
}

input.error,
select.error,
textarea.error {
  border: 1px solid $color_orange;
}

.c-section-privacy{
  margin-top:20px;
}

.p-home-contact_privacylink{
  color: blue;
  text-decoration: underline;
}