@use "../../global" as *;

.p-complete {
  background-color: $color_pale_orange;
  min-height: calc(100vh - 160px);
  padding: 80px 0 125px;

  @media (max-width: $md) {
    min-height: calc(100vh - 160px);
    padding: 40px 0 80px;
  }
}

.p-complete__done-mark {
  margin: auto;
  width: 90px;

  @media (max-width: $md) {
    width: 70px;
  }
}

.p-complete__success-message {
  color: $color_orange;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-top: 15px;
  text-align: center;

  @media (max-width: $md) {
    font-size: 20px;
  }
}

.p-complete__text {
  font-weight: 600;
  margin-top: 34px;
  text-align: center;
}

.p-complete__button-wrap {
  margin-top: 40px;
}

.p-complete__home-back-button {
  background: $color_orange_gradient_y;
  border-left: 1px solid #fe8410;
  border-right: 1px solid #ffa800;
  color: $color_white;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0.05em;
  margin: 0 auto;
  max-width: 270px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 16px;
    max-width: 288px;
  }

  &:before,
  &:after {
    background: $color_orange_gradient_y;
    content: "";
    display: block;
    height: 1px;
  }
}
