@use "../../global" as *;

.c-container {
  margin: 0 16px;
  max-width: 1080px;

  @media (min-width: $md) {
    margin: 0 auto;
    width: 90%;
  }
}
