@use "../../global" as *;

.c-section-heading {
  color: $color_orange;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;

  @media (min-width: $md) {
    font-size: 32px;
  }
}
