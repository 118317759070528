@use "../../global" as *;

.p-header {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1080px;
  position: relative;
  width: 90%;
  z-index: 999;

  @media (max-width: $md) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 55px;
    margin: auto;
    padding: 0 16px;
    width: auto;
  }
}

.p-header__logo {
  height: 54px;
}

.p-header__top-link {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.p-header__logo-img {
  width: 54px;

  @media (max-width: $md) {
    width: 37px;
  }
}

.p-header__logo-title {
  background: $color_orange_gradient_x;
  -webkit-background-clip: text;
  color: $color_orange;
  font-size: 32px;
  font-weight: 600;
  -webkit-text-fill-color: transparent;

  @media (max-width: $md) {
    font-size: 24px;
  }
}

.p-header__nav-toggle {
  display: none;

  @media (max-width: $md) {
    display: block;
    height: 30px;
    width: 25px;
    z-index: 998;
  }
}

.p-header__nav-toggle.is-open {
  .p-header__toggle-line:first-of-type {
    -webkit-transform: translateY(8px) rotate(135deg);
    -ms-transform: translateY(8px) rotate(135deg);
    transform: translateY(8px) rotate(135deg);
  }

  .p-header__toggle-line:nth-of-type(2) {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  .p-header__toggle-line:last-of-type {
    -webkit-transform: translateY(-8px) rotate(-135deg);
    -ms-transform: translateY(-8px) rotate(-135deg);
    transform: translateY(-8px) rotate(-135deg);
  }
}

.p-header__toggle-line {
  background-color: $color_grey;
  content: "";
  display: block;
  height: 3px;
  margin: 5px 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.p-header__nav-wrap {
  @media (max-width: $md) {
    background-color: $color_white;
    height: 100vh;
    left: 0;
    position: fixed;
    top: -100vh;
    transition: top 0.5s;
    width: 100vw;
  }

  &.is-open {
    top: 0;
  }
}

.p-header__nav--pc {
  @media (max-width: $md) {
    display: none;
  }
}

.p-header__nav-list--pc {
  align-items: center;
  display: flex;
  justify-content: center;
}

.p-header__nav-item--pc {
  font-weight: 600;

  &:not(:first-of-type) {
    margin-left: 25px;
  }
}

.p-header__nav-button {
  @media (min-width: $md) {
    background: $color_orange_gradient_x;
    color: $color_white;
    height: 50px;
    width: 200px;
  }
}

.p-header__nav--sp {
  display: none;

  @media (max-width: $md) {
    background-color: $color_white;
    display: none;
    height: 100vh;
    position: absolute;
    top: 55px;
    width: 100vw;
  }
}

.p-header__nav-list--sp {
  padding: 40px 30px;
}

.p-header__nav-item--sp {
  font-weight: 600;
  letter-spacing: 0.05em;

  &:not(:first-of-type) {
    margin-top: 25px;
  }
}

.js-scroll-prevent {
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
