$font_style: "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Meiryo", sans-serif !default;

$color_black: #333 !default;
$color_black_secondary: #aaa !default;
$color_white: #fff !default;
$color_white_secondary: #f7f7f7 !default;
$color_orange: #fd7516 !default;
$color_pale_orange: #fff5e8 !default;
$color_orange_gradient_x: linear-gradient(
  90deg,
  #fe8410 1.7%,
  #fe8410 29.86%,
  #fe8f0b 52.39%,
  #ff9c05 75.94%,
  #ffa800 100%
) !default;
$color_orange_gradient_y: linear-gradient(
  180deg,
  #fd7516 0%,
  #fed321 99%
) !default;
$color_pale_orange: #fff5e8 !default;
$color_grey: #777 !default;

$lg: 1080px !default;
$md: 767px !default;
$sm: 576px !default;
