@use "../global" as *;

.u-br--pc {
  @media (max-width: $md) {
    display: none;
  }
}

.u-br--sp {
  @media not all and (max-width: $md) {
    display: none;
  }
}

.u-br--xs {
  @media not all and (max-width: $sm) {
    display: none;
  }
}

.animate__delay-02s {
  animation-delay: 0.2s;
}

.animate__delay-03s {
  animation-delay: 0.3s;
}

.animate__delay-04s {
  animation-delay: 0.4s;
}

.animate__delay-05s {
  animation-delay: 0.5s;
}

.animate__delay-06s {
  animation-delay: 0.6s;
}

.animate__delay-07s {
  animation-delay: 0.7s;
}

.animate__delay-08s {
  animation-delay: 0.8s;
}

.u-text-animation {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.u-text-animation span {
  display: block;
  transform: translate(0, 105%);
  transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
}

.u-text-animation.-visible span {
  transform: translate(0, 0);
}

.u-text-animation span:nth-child(2) {
  transition-delay: 0.06s;
}

.u-text-animation span:nth-child(3) {
  transition-delay: 0.12s;
}

.u-text-animation span:nth-child(4) {
  transition-delay: 0.18s;
}

.u-text-animation span:nth-child(5) {
  transition-delay: 0.24s;
}

.u-text-animation span:nth-child(6) {
  transition-delay: 0.3s;
}

.u-text-animation span:nth-child(7) {
  transition-delay: 0.36s;
}

.u-text-animation span:nth-child(8) {
  transition-delay: 0.42s;
}

.u-text-animation span:nth-child(9) {
  transition-delay: 0.48s;
}

.u-text-animation span:nth-child(10) {
  transition-delay: 0.54s;
}

.u-text-animation span:nth-child(11) {
  transition-delay: 0.6s;
}

.u-text-animation span:nth-child(12) {
  transition-delay: 0.66s;
}

.u-text-animation span:nth-child(13) {
  transition-delay: 0.72s;
}

.u-text-animation span:nth-child(14) {
  transition-delay: 0.78s;
}

.swiper-container {
  @media (max-width: $md) {
    width: 100%;
  }
}

.swiper-wrapper {
  @media (max-width: $md) {
    width: 100%;
  }
}

.swiper-slide {
  @media (max-width: $md) {
    width: 232px;
  }
}
