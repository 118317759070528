@use "../../global" as *;

.p-confirm {
  background-color: $color_pale_orange;
  min-height: calc(100vh - 160px);
  padding: 80px 0;

  @media (max-width: $md) {
    min-height: calc(100vh - 160px);
    padding: 40px 0;
  }
}

.p-confirm__form {
  letter-spacing: 0.05em;
  margin: 20px auto 0;
  max-width: 500px;
}

.p-confirm__form-wrap {
  background-color: $color_white;
  color: $color_grey;
  font-size: 14px;
  padding: 40px;
}

.p-confirm__form-group {
  &:not(:first-of-type) {
    margin-top: 24px;
  }
}

.p-confirm__form-content {
  margin-top: 4px;
}

.p-confirm__button-wrap {
  margin-top: 20px;

  @media (max-width: $md) {
    margin-top: 10px;
  }
}

.p-confirm__submit-button {
  background: $color_orange_gradient_y;
  border-left: 1px solid #fe8410;
  border-right: 1px solid #ffa800;
  color: $color_white;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0.05em;
  margin: 0 auto;
  max-width: 270px;
  width: 100%;

  @media (max-width: $md) {
    font-size: 16px;
    max-width: 288px;
  }

  &:before,
  &:after {
    background: $color_orange_gradient_y;
    content: "";
    display: block;
    height: 1px;
  }
}

.p-confirm__back-button {
  background-color: $color_white;
  border: 1px solid $color_grey;
  color: $color_grey;
  height: 50px;
  margin: 10px auto 0;
  width: 150px;

  @media (max-width: $md) {
    font-size: 16px;
    max-width: 288px;
    width: 100%;
  }
}
